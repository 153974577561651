<template>
    <div class="clearfix img-box" :style="{height:height}">
        <img class="img" :src="require('@/assets/img/'+img)" :width="width" :height="height" alt="">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "ImgBox",
    props:{
        img:{
            type:String,
            default:''
        },
        width:{
            type:String,
            default: "100%"
        },
        height:{
            type:String,
            default: "100%"
        },
    }
}
</script>

<style lang="scss" scoped>
.img-box{
    position: relative;
    font-size: 0;
}
img{
    display: block;
    float: left;
}
</style>