import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/views/HomePage.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '',
        component: HomePage,
        meta: {
            title: '货想车-发货_拉货，一站式物流货运平台',
            content:{
                keywords:'货想车，发货，拉货，物流平台，配货平台，危化品运输，货物运输',
                description:'货想车是一个互联网物流服务平台，提供普货、危化品运输服务，平台覆盖包括面包车、大货车、罐车等在内的各种运输车型，货主通过app一键发布货源，司机在线抢单并完成运输。货想车提供货源发布、在线接单、轨迹追踪、在线结算运费等服务，对整个运输环节提供服务支持。',
            },

        }
    },
    {
        path: '/cargo',
        name: 'cargo',
        component: () => import('../views/cargo'),
        meta: {
            title: '货想车货主版-货主一键发货平台下载',
            content:{
                keywords:'货想车，货主版，货主版下载，货想车下载，物流平台',
                description:'货想车货主版是专为货主定制的发货工具，货主在线发布货源，司机在线抢单，实时运输轨迹追踪保障货物安全。本页提供货想车货主版下载地址，货主可免费下载。',
            },

        }
    },
    {
        path: '/driver',
        name: 'driver',
        component: () => import('../views/driver'),
        meta: {
            title: '货想车车主版-车主找货配货物流平台下载',
            content:{
                keywords:'货想车，车主版，车主版下载，货想车下载，配货平台',
                description:'货想车车主版是专为车主定制的物流配货找货平台，支持路线订阅，在线抢单，提供路线推荐，规避禁区、限行。本页提供货想车车主版下载地址，车主可免费下载。',
            },

        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About'),
        meta: {
            title: '货想车公司介绍',
            content:{
                keywords:'货想车，公司介绍',
                description:'货想车是蓝兔子互联科技（武汉）有限公司推出的整车物流撮合平台，以大数据和AI驱动整个运输流程的智能化重构。用移动互联网最新技术提高车货匹配效率，降低空驶率，降低物流行业运营成本。',
            },

        }
    },
    {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('../views/contactUs'),
        meta: {
            title: '货想车平台联系方式',
            content:{
                keywords:'货想车，联系我们',
                description:'货想车平台联系方式，客服电话400-123-4567',
            },

        }
    },
    {
        path: '/appDownload',
        name: 'appDownload',
        component: () => import('../views/appDown'),
        meta: {
            title: '货想车app下载',
            content:{
                keywords:'货想车，货想车下载，货想车货主下载，货想车司机下载',
                description:'通过二维码扫码或在应用商店搜索可以下载货想车应用app',
            },
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面meta */
    console.log(to.meta.content)
    if(to.meta.content){
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }
    // /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next()
});

export default router
