<template>
    <div class="main-header" ref="header" :style="{background:bg}">
        <div class="header">
            <h1 class="logo">
                <a href="http://huoxche.com" title="货想车-发货_拉货，一站式物流货运平台">
                    货想车-发货_拉货，一站式物流货运平台
                    <img title="货想车-发货_拉货，一站式物流货运平台" :src="scrollTop<=100 && bgColor === 'none'?require('@/assets/logo.png'):require('@/assets/logo_2.png')" alt="">
                </a>
            </h1>
            <div class="menu" :class="{'menu-black':scrollTop <= 100 && bgColor === 'none'?false:true}">
                <router-link class="menu-link" :class="{active:$route.path==='/'}" to="/">首页</router-link>
                <router-link class="menu-link" :class="{active:$route.path==='/cargo'}" to="/cargo">货主端</router-link>
                <router-link class="menu-link" :class="{active:$route.path==='/driver'}" to="/driver">司机端</router-link>
                <router-link class="menu-link" :class="{active:$route.path==='/about'}" to="/about">公司介绍</router-link>
                <router-link class="menu-link" :class="{active:$route.path==='/contactUs'}" to="/contactUs">联系我们</router-link>
                <router-link class="menu-link" :class="{active:$route.path==='/appDownload'}" to="/appDownload">APP下载</router-link>
            </div>
        </div>
        <div style="height: 80px"></div>
    </div>
</template>

<script>
export default {
    name: "l-header",
    props:{
        bgColor:{
          type:String,
          default:'none'
      }
    },
    data() {
        return {
            scrollTop:0,
        }
    },
    mounted() {
        console.log(this.$route.path);
        document.addEventListener('scroll', this.scrollEvent)
    },
    methods: {
        scrollEvent() {
           this.scrollTop = document.body.scrollTop + document.documentElement.scrollTop;
        }
    },
    computed:{
        bg(){
            if(this.bgColor === "none"){
                if(this.scrollTop > 100){
                    return '#fff'
                }else{
                    return 'none'
                }
            }
            return this.bgColor;
        }
    }
}
</script>

<style lang="scss" scoped>
.main-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: all ease-in .2s;
}

.header {
    height: 80px;
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.logo {
    flex: 0 0 172px;
    font-size: 0;
    text-indent:-9999px
    img{
        width: 100%;
    }
}

.menu {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .menu-link {
        color: #fff;
        font-size: 18px;
        margin-left: 2%;
        text-decoration: none;
        position: relative;
        font-weight: 500;
        &.active {
            color: #FD2A1F;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -12px;
                width: 100%;
                height: 1px;
                border-bottom: 2px solid #FD2A1F;
            }
        }
    }
    &.menu-black{
        .menu-link{
            color: #000;
            &.active{
                color: #FD2A1F;
            }
        }
    }
}
</style>