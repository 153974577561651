<template>
    <div class="home">
        <l-header></l-header>
        <img-box img="banner.png">
            <div class="e-code">
                <div class="code-content">
                    <div class="code-item">
                        <div class="qr-code">
                            <img-box img="cargo_qr.png" width="137px" height="137px"></img-box>
                        </div>
                        <span class="br"></span>
                        <span class="title">货主端APP下载</span>
                        <span class="more">可在手机应用商店搜索下载</span>
                    </div>
                    <div class="code-item">
                        <div class="qr-code">
                            <img-box img="driver_qr.png" width="137px" height="137px"></img-box>
                        </div>
                        <span class="br"></span>
                        <span class="title">司机端APP下载</span>
                        <span class="more">可在手机应用商店搜索下载</span>
                    </div>
                </div>
            </div>
        </img-box>
        <div class="ys">
            <h4>我们的优势</h4>
            <div class="img-list">
                <div class="img-item" @mouseenter="mEnter(1)" @mouseleave="mLeave">
                    <img-box class="ys-img" img="home_1.png" width="100%" height="100%"></img-box>
                    <div class="amint-box" :style="{bottom:amintIndex===1?'0':'-100%'}">
                        <div class="service-content">
                            <img-box class="ys-img" img="amint_1.png" width="114px" height="93px"></img-box>
                            <span class="br"></span>
                            <span class="text">一站式发货</span>
                            <span class="text">司机急速响应</span>
                        </div>
                    </div>
                </div>
                <div class="img-item" @mouseenter="mEnter(2)" @mouseleave="mLeave">
                    <img-box class="ys-img" img="home_2.png" width="100%" height="auto"></img-box>
                    <div class="amint-box" :style="{bottom:amintIndex===2?'0':'-100%'}">
                        <div class="service-content">
                            <img-box class="ys-img" img="amint_2.png" width="114px" height="93px"></img-box>
                            <span class="br"></span>
                            <span class="text">费用线上约定</span>
                            <span class="text">交易有凭证</span>
                        </div>
                    </div>
                </div>
                <div class="img-item" @mouseenter="mEnter(3)" @mouseleave="mLeave">
                    <img-box class="ys-img" img="home_3.png" width="100%" height="auto"></img-box>
                    <div class="amint-box" :style="{bottom:amintIndex===3?'0':'-100%'}">
                        <div class="service-content">
                            <img-box class="ys-img" img="amint_3.png" width="114px" height="93px"></img-box>
                            <span class="br"></span>
                            <span class="text">精准分类</span>
                            <span class="text">智能推送精准货源</span>
                        </div>
                    </div>
                </div>
                <div class="img-item" @mouseenter="mEnter(4)" @mouseleave="mLeave">
                    <img-box class="ys-img" img="home_4.png" width="100%" height="auto"></img-box>
                    <div class="amint-box" :style="{bottom:amintIndex===4?'0':'-100%'}">
                        <div class="service-content">
                            <img-box class="ys-img" img="amint_4.png" width="114px" height="93px"></img-box>
                            <span class="br"></span>
                            <span class="text">司机货主实名认证</span>
                            <span class="text">货物轨迹可查询</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="car-type">
            <h4>服务车型</h4>
            <div class="swiper">
                 <div class="swiper-box" :style="{marginLeft:-(100*bannerOptions.activeIndex)+'%'}">
                     <img-box class="img-item" v-for="(item,index) of bannerList" :key="index" :img="item"></img-box>
                 </div>
                <div class="dats">
                    <div class="pav iconfont" @click="pavImg">&#xe624;</div>
                    <div class="banner-index">
                        <span v-for="item of bannerList.length" :key="item" @click="toActive(item)" :class="{active:bannerOptions.activeIndex===item-1}">{{item}}</span>
                    </div>
                    <div class="next iconfont" @click="nextImg">&#xe624;</div>
                </div>
            </div>
        </div>
        <img-box img="ywfw.png"></img-box>
        <l-footer></l-footer>

    </div>
</template>

<script>
// @ is an alias to /src
import LHeader from "@/components/Header"
import ImgBox from "@/components/ImgBox";
import LFooter from "@/components/LFooter";

export default {
    name: 'Home',
    components: {
        LHeader,
        ImgBox,
        LFooter
    },
    data() {
        return {
            amintIndex: null,
            bannerList:[
                'cartype1.png',
                'cartype2.png',
            ],
            bannerOptions:{
                deep:5000,
                activeIndex:0,
                timer:null,
            },
        }
    }
    ,
    mounted() {
        // new QRCode(this.$refs.qrCodeDiv1, {
        //     text: config.cargoQR,
        //     width: 137,
        //     height: 137,
        //     colorDark: "#333333", //二维码颜色
        //     colorLight: "#ffffff", //二维码背景色
        //     correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        // })
        // new QRCode(this.$refs.qrCodeDiv2, {
        //     text: config.driverQR,
        //     width: 137,
        //     height: 137,
        //     colorDark: "#333333", //二维码颜色
        //     colorLight: "#ffffff", //二维码背景色
        //     correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        // })
        this.autoPlay();
    },
    methods: {
        mEnter(index) {
            this.amintIndex = index;
        }
        ,
        mLeave() {
            this.amintIndex = null;
        },
        autoPlay(){
            this.bannerOptions.timer = setInterval(()=>{
                let index = this.bannerOptions.activeIndex;
                if(index >= this.bannerList.length-1){
                    this.$set(this.bannerOptions,'activeIndex',0);
                }else{
                    let activeIndex = index+=1;
                    this.$set(this.bannerOptions,'activeIndex',activeIndex);
                }
            },this.bannerOptions.deep)
        },
        pavImg(){
            let index = this.bannerOptions.activeIndex;
            if(index === 0) return;
            let activeIndex = index-1;
            this.$set(this.bannerOptions,'activeIndex',activeIndex);
        },
        nextImg(){
            let index = this.bannerOptions.activeIndex;
            if(index === this.bannerList.length-1) return;
            let activeIndex = index+1;
            this.$set(this.bannerOptions,'activeIndex',activeIndex);
        },
        toActive(index){
            this.$set(this.bannerOptions,'activeIndex',index-1);
        }

    },
    beforeDestroy() {
        clearInterval(this.bannerOptions.timer);
    }
}
</script>

<style scoped lang="scss">
.ys {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;

    h4 {
        font-size: 40px;
        color: #FFFFFF;
        margin-bottom: 80px;
        position: absolute;
        top: 100px;
        z-index: 9;

        &:after {
            content: "";
            position: absolute;
            left: 47px;
            bottom: -14px;
            width: 99px;
            height: 4px;
            background: #FD6504;
        }
    }

    .img-list {
        display: flex;
        flex-direction: row;
        width: 100%;

        .img-item {
            display: flex;
            flex-direction: row;
            width: 100%;
            position: relative;

            .amint-box {
                position: absolute;
                left: 0;
                bottom: -100%;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, .5);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                transition: all ease-in .3s;

                .service-content {
                    height: 230px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .br {
                        display: block;
                        width: 135px;
                        border: 2px solid #E1E1E1;
                        margin: 30px 0;
                    }

                    .text {
                        font-size: 29px;
                        color: #fff;
                        margin-bottom: 20px;
                    }
                }
            }

            .ys-img {
                flex: 1;
            }
        }
    }
}
.swiper{
    width: 100%;
    overflow: hidden;
    position: relative;
    .swiper-box{
        width: 200%;
        display: flex;
        margin-left: 0;
        flex-direction: row;
        position: relative;
        transition: margin-left ease-in-out .6s;
    }
    .img-item{
        width: 100%;
    }
    .dats{
        display: flex;
        flex-direction: row;
        margin-bottom: 50px;
        width: 100%;
        height: 68px;
        .pav{
            margin-left: 120px;
            width: 68px;
            height: 68px;
            border: 1px solid #DDDDDD;
            color: #ACACAC;
            text-align: center;
            line-height: 68px;
            font-size: 30px;
            cursor: pointer;
        }
        .next{
            margin-right: 120px;
            width: 68px;
            height: 68px;
            border: 1px solid #DDDDDD;
            color: #ACACAC;
            text-align: center;
            line-height: 68px;
            font-size: 30px;
            transform: rotate(180deg);
            cursor: pointer;
        }
        .banner-index{
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            span{
                width: 14px;
                height: 14px;
                overflow: hidden;
                background: #F4F4F4;
                margin-right: 10px;
                text-indent: 100px;
                cursor: pointer;
                &.active{
                    background: #FD2A1F;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}
.car-type {
    background: #fff;
    padding-top: 78px;

    h4 {
        font-size: 40px;
        color: #333333;
        margin-bottom: 80px;
        z-index: 9;
        text-align: center;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -26px;
            width: 100px;
            height: 4px;
            margin-left: -50px;
            background: #FD6504;
        }
    }
}

.e-code {
    position: absolute;
    top: 250px;
    left: 0;
    width: 100%;

    .code-content {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .code-item {
            width: 178px;
            height: 230px;
            background: #fff;
            margin-right: 33px;
            border-radius: 10px;

            &:last-child {
                margin-right: 0;
            }

            .qr-code {
                margin-top: 15px;
                margin-left: 20px;
            }

            .br {
                display: block;
                margin: 13px;
                border-top: 2px solid #E8E8E8;
            }

            .title {
                display: block;
                font-size: 19px;
                color: #333333;
                text-align: center;
            }
            .more{
                display: block;
                padding-top: 5px;
                font-size: 14px;
                padding-left: 4px;
            }
        }
    }
}
</style>
