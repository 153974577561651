<template>
    <div class="footer">
        <div class="footer-content">
            <div class="footer-top">
                <div class="item1">
                    <h4>客户入口</h4>
                    <div class="item-info"><img-box img="head.png" width="30px" height="30px"></img-box><span><router-link to="/cargo">货主加入</router-link></span></div>
                    <div class="item-info"><img-box img="head2.png" width="30px" height="30px"></img-box><span><router-link to="/driver">车主加入</router-link></span></div>
                </div>
                <div  class="item2">
                    <h4>联系我们</h4>
                    <div class="item-info"><img-box img="kefu.png" width="30px" height="30px"></img-box><span>400-007-2118</span></div>
                    <div class="item-info"><img-box img="chat.png" width="30px" height="30px"></img-box><span>huoxche666</span></div>
                    <div class="item-info"><img-box img="email.png" width="30px" height="30px"></img-box><span>873768608@qq.com</span></div>
                </div>
                <div class="item3">
                    <div class="qr-code-box">
                        <div class="qr-item">
                            <div class="qr-item">
                                <div class="qr-code">
                                    <img-box img="cargo_qr.png" width="123px" height="123px"></img-box>
                                </div>
                            </div>
                        </div>
                        <b>货主端APP下载</b>
                    </div>
                    <div class="qr-code-box">
                        <div  class="qr-item"><div class="qr-code">
                            <img-box img="driver_qr.png" width="123px" height="123px"></img-box>
                        </div></div>
                        <b>司机端APP下载</b>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <span>版本所有.AII Rights Reseved 蓝兔子互联科技（武汉）有限公司</span>
                <span><a href="http://beian.miit.gov.cn/" target="_blank">鄂ICP备2020018529号-1</a></span>
                <span><a href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank">鄂公网安备42010502001305号</a></span>
            </div>
        </div>
    </div>
</template>

<script>
import ImgBox from "@/components/ImgBox";
export default {
    name: "LFooter",
    components:{
        ImgBox
    },
    mounted() {

    },
}
</script>

<style lang="scss" scoped>
.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #272727;
    padding: 52px 0;
    .footer-content{
        max-width: 1200px;
        margin: 0 auto;
    }
    .footer-top{
        display: flex;
        flex-direction: row;
        h4{
            font-size: 28px;
            color: #989490;
            font-weight: 400;
            margin-bottom: 45px;
        }
    }
    .item1{
        margin-right: 93px;
    }
    .item2{
        flex: 1;
    }
    .item3{
        display: flex;
        flex-direction: row;
        padding-top: 16px;
        .qr-code-box{
            display: flex;
            flex-direction: column;
            margin-left: 54px;
            &:first-child{
                margin-left: 0;
            }
            .qr-item{
                width: 134px;
                height: 134px;
                background: #fff;
            }
            .qr-code{
               margin: 5px 0 0 5px;
            }
            b{
                width: 100%;
                text-align: center;
                font-size: 18px;
                color: #fff;
                padding-top: 26px;
                font-weight: 400;
            }
        }
    }
    .item-info{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 28px;
        span{
            display: inline-block;
            padding-left: 14px;
            font-size: 22px;
            color: #E5DFD9;
            font-weight: 400;
            a{
                color: #E5DFD9;
                text-decoration: none;
            }
        }
    }
.footer-bottom{
    text-align: center;
    font-size: 14px;
    color: #989490;
    margin-top: 80px;
    span{
        &:nth-child(2){
            padding: 0 94px;
        }
        a{
            color: #989490;
        }
    }
}
}
</style>